import CheckIcon from "@mui/icons-material/Check";
import { GlobalStyles, Grid, ScopedCssBaseline, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { ThemeProvider, styled, useTheme } from "@mui/material/styles";
import { ClientError } from "graphql-request";
import { useStateMachine } from "little-state-machine";
import * as React from "react";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { Control, FieldValues, UseFormRegister, useForm } from "react-hook-form";
import { UseMutationResult } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { __DEV__, adobe_datalayer_launch_link, PAGEVIEW, processENV } from "../api";
import getTranslationJson from "../components/getTranslationJson";
import Currency from "../components/currency";
import GumLogo from "../components/gumLogo";
import MessageSnackbars from "../components/messageSnackBar";
import StripeExpressCheckoutElement from "../components/stripeExpressCheckoutElement";
import ThemeToggle from "../components/themeToggle";
import {
  addPageViewTrackingEvent,
  addShippingInfoTrackingEvent,
  AdobeDataLayerTrackingInit,
  beginCheckoutTrackingEvent,
  initTrackingEvents,
} from "../components/trackingEvents";
import { CheckoutContext, CheckoutSetContext, ColorModeContext } from "../context/checkoutContext";
import "../css/checkout.css";
import { updateAction, updateCustomerAction, updateDataAction } from "../globalState";
import { CustomerFormType } from "../globalState/global";
import {
  useCheckoutOpen,
  useCheckoutStatus,
  useCheckoutSubmit,
  useCheckoutUpdate,
  usePaypalSessionPay,
} from "../hooks/useCheckout";
import { useCustomerCreate } from "../hooks/useCustomer";
import { useNewsletterSubscribe } from "../hooks/useNewsletter";
import { themeDark, themeLight } from "../themes";
import CompleteForm from "./checkoutComplete";
import CustomerForm from "./checkoutCustomer";
import PaymentForm from "./checkoutPayment";
import useScript from "../hooks/useScript";
// import adobeClientDataLayer from "@adobe/adobe-client-data-layer";

export enum CheckoutStatusEnum {
  OPEN = "open",
  SUBMITTED = "submitted",
  CANCELED = "cancelled",
  PROCESSING = "processing",
  COMPLETE = "complete",
  EXPIRED = "expired",
  FAILED = "failed",
}

const GrayWrapBoxTitleBackground = styled("span")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "0px 16px",
}));
const GrayWrapBoxTitleBackgroundSplit = styled("span")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "0px 16px",
  background:
    theme.palette.mode === "dark"
      ? "linear-gradient(0deg, #1a2027 50%, #111111 55%)"
      : theme.palette.background.default,
}));
const GrayWrapBoxTitle = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "16px",
  position: "absolute",
  top: "-12px",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const GrayWrapBox = styled("div")(({ theme }) => ({
  // marginTop: "24px",
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#FFFFFF",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#CDD4DC" : "#CDD4DC",
  padding: "14px 12px 12px 12px",
  borderRadius: "16px",
  fontFamily: "Gotham",
  color: theme.palette.primary.main,
  position: "relative",
}));
const SpaceBox = styled("div")(({ theme }) => ({
  paddingTop: "20px",
}));
const GrayLineBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#FFFFFF",
  borderTop: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#CDD4DC" : "#CDD4DC",
  // padding: "14px 12px 12px 12px",
  lineHeight: "10px",
  fontFamily: "Gotham",
  color: theme.palette.primary.main,
  marginTop: "20px",
  marginBottom: "20px",
  position: "relative",
}));
const GrayLine = styled("div")(({ theme }) => ({
  borderBottom: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#CDD4DC" : "#CDD4DC",
  margin: "16px 0px",
}));
const GrayBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F8F8F8",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#CDD4DC",
  padding: "24px",
  borderRadius: "16px",
  fontFamily: "Gotham",
  color: theme.palette.primary.main,
}));
const OrderTitleText = styled("span")(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));
const OrderNeedHelpText = styled("span")(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 700,
  fontSize: "14px",
  display: "none",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const OrderLineText = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const OrderLinePriceText = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const OrderLineShippingText = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "16px",
  marginTop: "12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const OrderLineShippingPriceText = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "16px",
  marginTop: "12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const OrderLineVATText = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "16px",
  marginTop: "12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const OrderLineVATPriceText = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "16px",
  marginTop: "12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const OrderLineDiscountText = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: "#00A944",
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "16px",
  marginTop: "12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const OrderLineDiscountPriceText = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: "#00A944",
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "16px",
  marginTop: "12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const OrderTotalText = styled("span")(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 700,
  fontSize: "20px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));
const OrderTotalVATText = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "12px",
  display: "inline",
  paddingLeft: "5px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
const OrderTotalPriceText = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 700,
  fontSize: "20px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));
const OrderOverlay = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F8F8F8",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#CDD4DC",
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 700,
  fontSize: "20px",
  display: "none",
  position: "fixed",
  zIndex: 1000,
  bottom: 0,
  right: 0,
  left: 0,
  textAlign: "center",
  padding: "14px",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));
const ShippingLineText = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const ShippingAddressDetails = styled("div")(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 700,
  fontSize: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const ShippingAddressChange = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.secondary.main,
  textDecoration: "underline",
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "14px",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const InfoText = styled("div")(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "30px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "30px",
  },
}));
const DescriptionText = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.mode === "dark" ? "#acaeba" : "#64656A",
  fontFamily: "Gotham",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "14px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const TermsAndConditions = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  // color: "#64656A",
  fontFamily: "Gotham",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "14px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const ExpressCheckoutWrapper = styled("div")(({ theme }) => ({
  // ...theme.typography.body2,
  paddingLeft: "10%",
  paddingRight: "10%",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "12px",
    paddingRight: "12px",
  },
}));
const ContinueButton = styled(Button)(({ theme, variant }) => ({
  cursor: "pointer",
  textTransform: "none",
  background: theme.palette.secondary.main,
  padding: "14px",
  marginTop: "12px",
  lineHeight: "20px",
  width: "100%",
  borderRadius: "16px",
  fontFamily: "Gotham",
  fontWeight: 600,
  fontSize: "16px",
  color: "#FFFFFF",
  boxShadow: "none",
  "&:hover": {
    background: theme.palette.secondary.main,
    color: "#FFFFFF",
  },
  "&:disabled": {
    background: theme.palette.secondary.main,
    color: "#bcbcbc",
  },
}));

const StepperWrapper = styled(Stepper)(({ theme, activeStep }) => ({
  // "+activeStep+"
  paddingTop: 0,
  paddingBottom: "5px",
  ["& .MuiStepConnector-line.MuiStepConnector-lineHorizontal::nth-of-type(-n+" + (activeStep || 0 + 1) + ")"]: {
    borderColor: theme.palette.secondary.main,
  },
}));
const LogoWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  marginTop: "34px",
  marginBottom: "34px",
  marginLeft: "0px",
  display: "flex",
  alignContent: "center",
  justifyContent: "space-between",
  alignItems: "center",
}));
export const LoaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  minHeight: "300px",

  [theme.breakpoints.down("sm")]: {
    minHeight: "80px",
  },
}));
export const LoaderWrapperSmall = styled(Box)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  minHeight: "80px",

  [theme.breakpoints.down("sm")]: {
    minHeight: "80px",
  },
}));

const StepIcon = ({ activeStep, label }: { activeStep: number; label: number }) => {
  const theme = useTheme();
  const color = theme.palette.secondary.main;
  const textColor = theme.palette.secondary.contrastText;
  const colorInactive = theme.palette.primary.main;
  const inactiveColor = theme.palette.primary.contrastText;
  const inactiveBorderColor = "#CDD4DC";
  const passedColor = theme.palette.secondary.light;
  return (
    <div style={{ position: "relative" }}>
      {/* <SquareRoundedIcon sx={{ fontSize: "30px" }} /> */}
      <Box
        sx={{
          width: "40px",
          height: "36px",
          backgroundColor: label === activeStep + 1 ? color : label < activeStep + 1 ? "#FFFFFF" : inactiveColor,
          borderWidth: label === activeStep + 1 ? 0 : label < activeStep + 1 ? 1 : 1,
          borderColor: label === activeStep + 1 ? color : label < activeStep + 1 ? passedColor : inactiveBorderColor,
          borderStyle: "solid",
          borderRadius: "12px",
        }}
      ></Box>
      <div
        style={{
          color: label === activeStep + 1 ? textColor : label < activeStep + 1 ? passedColor : colorInactive,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          textAlign: "center",
          lineHeight: "36px",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {label === 3 ? <CheckIcon sx={{ fontSize: "18px", lineHeight: "18px" }} /> : label}
      </div>
    </div>
  );
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://playbrush.com/">
        Playbrush
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const GetTermsAndConditionsText = (
  translation: GQL_Prismic.Checkout_Translations | null,
  terms_and_condition_text: string | null | undefined
) => {
  let accept_privacy_text = terms_and_condition_text || "I aggree to the data privacy policy.";
  if (translation?.accept_privacy_word && translation?.accept_privacy_url) {
    accept_privacy_text = accept_privacy_text.replace(
      new RegExp(translation?.accept_privacy_word, "gi"),
      "<a target='_blank' href=" + translation?.accept_privacy_url + ">" + translation?.accept_privacy_word + "</a>"
    );
  }
  if (translation?.accept_cancellation_word && translation?.accept_cancellation_url) {
    accept_privacy_text = accept_privacy_text.replace(
      new RegExp(translation?.accept_cancellation_word, "gi"),
      "<a target='_blank' href=" +
        translation?.accept_cancellation_url +
        ">" +
        translation?.accept_cancellation_word +
        "</a>"
    );
  }
  if (translation?.accept_terms_word && translation?.accept_terms_url) {
    accept_privacy_text = accept_privacy_text.replace(
      new RegExp(translation?.accept_terms_word, "gi"),
      "<a target='_blank' href=" + translation?.accept_terms_url + ">" + translation?.accept_terms_word + "</a>"
    );
  }

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: accept_privacy_text,
      }}
    />
  );
};

const GetStepContent = ({
  step,
  register,
  control,
  errors,
  getValues,
  setValue,
  cartData,
  translation,
  mutationPaypalSessionPay,
  setActiveStep,
  setPaypalPaymentLoading,
  setMessageSnackBarOpen,
  setMessageSnackBar,
  payNowButtonRef,
  isLoadingPayNowConfirm,
  setIsLoadingPayNowConfirm,
  processing,
}: {
  step: number;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  errors: {
    [x: string]: any;
  };
  getValues: any;
  setValue: any;
  cartData: GQL.CheckoutOpen | GQL.CheckoutUpdate | null;
  translation: GQL_Prismic.Checkout_Translations | null;
  mutationPaypalSessionPay: UseMutationResult<
    GQL.PaypalSessionPay,
    ClientError,
    GQL.MutationPaypalSessionPayArgs,
    unknown
  >;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setPaypalPaymentLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageSnackBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageSnackBar: React.Dispatch<React.SetStateAction<string>>;
  payNowButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  isLoadingPayNowConfirm: boolean;
  setIsLoadingPayNowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  processing?: boolean;
}) => {
  const theme = useTheme();
  if (cartData === null && processing !== true) {
    return <InfoText>{translation?.cart_session_expired || "Cart Expired please go back"}</InfoText>;
  }
  // if (cartData && cartData.customerId && step === 0) {
  //   step = 2;
  // }
  switch (step) {
    case 0:
      return (
        <>
          <CustomerForm
            register={register}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            cartData={cartData}
            translation={translation}
            setMessageSnackBarOpen={setMessageSnackBarOpen}
            setMessageSnackBar={setMessageSnackBar}
          />
        </>
      );
    case 1:
      return (
        <PaymentForm
          cartData={cartData}
          translation={translation}
          mutationPaypalSessionPay={mutationPaypalSessionPay}
          setActiveStep={setActiveStep}
          setPaypalPaymentLoading={setPaypalPaymentLoading}
          setMessageSnackBarOpen={setMessageSnackBarOpen}
          setMessageSnackBar={setMessageSnackBar}
          payNowButtonRef={payNowButtonRef}
          isLoadingPayNowConfirm={isLoadingPayNowConfirm}
          setIsLoadingPayNowConfirm={setIsLoadingPayNowConfirm}
        />
      );
    case 2:
      return (
        <CompleteForm
          cartData={cartData}
          translation={translation}
          setMessageSnackBarOpen={setMessageSnackBarOpen}
          setMessageSnackBar={setMessageSnackBar}
        />
      );
    default:
      throw new Error("Unknown step");
  }
};

const Checkout = ({ processing }: { processing?: boolean }) => {
  initTrackingEvents();

  let { cartId, checkoutId } = useParams();

  const { actions, state, getState } = useStateMachine({
    updateAction,
    updateCustomerAction,
    updateDataAction,
  });

  const [activeStep, setActiveStep] = useState<number>(0);
  const [cartCheckDone, setCartCheckDone] = useState(false);
  const [cartContainsAdults, setCartContainsAdults] = useState(false);
  const [cartContainsKids, setCartContainsKids] = useState(false);
  const [cartData, setCartData] = useState<GQL.CheckoutOpen | GQL.CheckoutUpdate | null>(state.data.cartData);
  const [translation, setTranslation] = useState<GQL_Prismic.Checkout_Translations | null>(null);
  const [paypalPaymentLoading, setPaypalPaymentLoading] = useState(false);

  const [messageSnackBarOpen, setMessageSnackBarOpen] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");

  const steps = [translation?.step_shipping || "Shipping address", translation?.step_payment || "Payment details", ""];

  const mutationCustomerCreate = useCustomerCreate();
  // const mutationCart = useCart();
  const mutationCheckoutOpen = useCheckoutOpen();
  const mutationCheckoutUpdate = useCheckoutUpdate();
  const mutationCheckoutSubmit = useCheckoutSubmit();
  const mutationCheckoutStatus = useCheckoutStatus();
  const mutationNewsletterSubscribe = useNewsletterSubscribe();

  const mutationPaypalSessionPay = usePaypalSessionPay();

  const payNowButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const [isLoadingPayNowConfirm, setIsLoadingPayNowConfirm] = useState(false);

  const handleNext = () => {
    handleSubmit(onSubmit)();
  };
  const handlePayNow = () => {
    if (payNowButtonRef && payNowButtonRef.current) {
      payNowButtonRef.current.click();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // This will go back one step in the browser history
  };

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const defaultMode = prefersDarkMode && false ? "dark" : "light";
  const [mode, setMode] = useState<"light" | "dark">(defaultMode);
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(() => (mode === "light" ? themeLight : themeDark), [mode]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const onSubmit = (data: CustomerFormType | { [x: string]: any }) => {
    if (__DEV__) {
      console.log("Submitted Data", data);
    }
    let customerData: GQL.MutationCustomerCreateArgs = {
      name: (data.first_name || "").trim() + " " + (data.last_name || "").trim(),
      email: (data.email || "").trim(),
      description: "",
      address: {
        city: data.billing_different ? data.bill_city : data.city,
        // country: data.billing_different ? data.bill_country.countryShortCode : data.country.countryShortCode,
        country: data.billing_different ? data.bill_country : data.country,
        line1: data.billing_different ? data.bill_address1 : data.address1,
        line2: data.billing_different ? data.bill_address2 : data.address2,
        postalCode: data.billing_different ? data.bill_zip : data.zip,
        // state: data.billing_different ? (data.bill_state ? data.bill_state.name : "") : data.state.name,
        state: "",
      },
      shipping: {
        name: (data.first_name || "").trim() + " " + (data.last_name || "").trim(),
        phone: data.phone,
        address: {
          city: data.city,
          country: data.country,
          // country: data.country.countryShortCode,
          line1: data.address1,
          line2: data.address2,
          postalCode: data.zip,
          // state: data.state ? data.state.name : "",
          state: "",
        },
      },
      phone: data.phone,
      currency: cartData && cartData.currency ? cartData.currency.toLowerCase() : "eur",
      metadata: JSON.stringify({
        first_name: data.first_name,
        last_name: data.last_name,
        company_name: data.company_name,
      })
        .replace(/\\n/g, "\\n")
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, "\\&")
        .replace(/\\r/g, "\\r")
        .replace(/\\t/g, "\\t")
        .replace(/\\b/g, "\\b")
        .replace(/\\f/g, "\\f"),
      firstName: data.first_name,
      lastName: data.last_name,
      companyName: data.company_name,
      language: cartData && cartData.language ? cartData.language.toLowerCase() : "en",
    };
    actions.updateCustomerAction(customerData);
    if (__DEV__) {
      console.log("State", state);
    }
    let isNewsletterChecked = data.subscribe_newsletter;
    if (isNewsletterChecked) {
      actions.updateDataAction({ isNewsletterChecked: true });
      let newsletterData: GQL.MutationNewsletterSubscribeArgs = {
        email: data.email,
        firstName: (data.first_name || "").trim(),
        lastName: (data.last_name || "").trim(),
        language: state.data.cartData?.language,
      };
      mutationNewsletterSubscribe.mutate(newsletterData);
    } else {
      actions.updateDataAction({ isNewsletterChecked: false });
    }
    mutationCustomerCreate.mutate(customerData, {
      onSuccess: (data) => {
        actions.updateDataAction({ stripeCustomerId: data.customerId });
        mutationCheckoutUpdate.mutate(
          {
            checkoutId: state.data.checkoutId,
            customerId: data.customerId,
            isNewsletterChecked: isNewsletterChecked,
          },
          {
            onSuccess: (data2) => {
              actions.updateDataAction({
                checkoutId: data2.checkoutId,
                cartData: data2,
              });
              if (__DEV__) {
                console.log("State", state);
              }
              if (data2.lineItems) {
                const cart_tags = data2.lineItems
                  ?.map((lineitem) => lineitem?.tags)
                  .reduce(
                    (accumulator, currentValue) => accumulator && currentValue && [...accumulator, ...currentValue],
                    []
                  );
                if (cart_tags && cart_tags.includes("adults") && cart_tags.includes("toothbrush")) {
                  setCartContainsAdults(true);
                }
                if (cart_tags && cart_tags.includes("kids") && cart_tags.includes("toothbrush")) {
                  setCartContainsKids(true);
                }
              }
              setCartData(data2);
              setActiveStep(activeStep + 1);
              addShippingInfoTrackingEvent(data2);
            },
          }
        );
        // navigate("/payment");
      },
      // TODO on error
    });
  };

  useEffect(() => {
    if (processing && processing) {
      setActiveStep(2);
      if (state.data && state.data.cartData && state.data.cartData.language) {
        setTranslation(getTranslationJson(state.data.cartData.language));
      }
      setCartCheckDone(true);
    } else if (cartId !== null && cartId !== undefined) {
      // If cart id presented open a Checkout (copy cart and create a checkout)

      mutationCheckoutOpen.mutate(
        { cartId: cartId },
        {
          onSuccess: (data3) => {
            actions.updateDataAction({
              checkoutId: data3.checkoutId,
              cartData: data3,
            });
            if (__DEV__) {
              console.log("State", state);
            }
            setCartData(data3);
            window.history.replaceState(null, "Checkout Playbrush", "/checkout");
            addPageViewTrackingEvent(data3, PAGEVIEW.CHECKOUT_BEGIN);
            beginCheckoutTrackingEvent(data3);
            setTranslation(getTranslationJson(data3.language || ""));
            setCartCheckDone(true);
            if (
              data3.checkoutMessage !== null &&
              data3.checkoutMessage !== undefined &&
              data3.checkoutMessage !== "" &&
              translation !== null
            ) {
              setMessageSnackBar(translation.coupon_not_valid_removed || "Coupon code is not valid any more");
              setMessageSnackBarOpen(true);
            }
          },
          onError: () => {
            setCartCheckDone(true);
            // TODO error
          },
        }
      );
    } else if (state.data.checkoutId !== null && state.data.checkoutId !== undefined && state.data.checkoutId !== "") {
      // If cart id  not presented but checkoutId exists read it up from state (store) and restore the session
      // debugger;

      mutationCheckoutStatus.mutate(
        { checkoutId: state.data.checkoutId },
        {
          onSuccess: (data4) => {
            if (data4.status === CheckoutStatusEnum.FAILED) {
              setActiveStep(0);
            }
            if (data4.status === CheckoutStatusEnum.SUBMITTED) {
              setActiveStep(1);
            }
            if (data4.status === CheckoutStatusEnum.COMPLETE) {
              if (data4.thankYouPage) {
                data4.thankYouPage = data4.thankYouPage.replace(/\/$|$/, "/");
                window.location.replace(data4.thankYouPage + "?checkoutId=" + state.data.checkoutId);
              } else {
                navigate("/thank_you/" + state.data.checkoutId);
              }
            }
            if (data4.status === CheckoutStatusEnum.EXPIRED) {
              setCartData(null);
              setCartCheckDone(true);
            } else {
              setCartData(state.data.cartData);
              addPageViewTrackingEvent(state.data.cartData, PAGEVIEW.CHECKOUT_BEGIN);
              beginCheckoutTrackingEvent(state.data.cartData);
            }
            if (state.data && state.data.cartData && state.data.cartData.language) {
              setTranslation(getTranslationJson(state.data.cartData.language));
            }
            setCartCheckDone(true);
          },
          onError: () => {
            setCartData(null);
            setCartCheckDone(true);
            // TODO error
          },
        }
      );
    } else {
      // expired session show
      setCartData(null);
      setCartCheckDone(true);
    }
    if (__DEV__) {
      console.log("State", state);
    }
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline enableColorScheme>
          <GlobalStyles
            styles={{
              body: {
                backgroundColor: theme.palette.background.default,
                marginBottom: "150px",
              },
            }}
          />
          <CheckoutContext.Provider value={cartData}>
            <CheckoutSetContext.Provider value={setCartData}>
              <Container component="main" maxWidth="lg" sx={{ mb: 12 }}>
                <LogoWrapper>
                  <Link
                    onClick={goBack}
                    sx={{
                      cursor: "pointer",
                    }}
                    // href={
                    //   processENV.REACT_APP_ENV_NAME === "dev" ? processENV.REACT_APP_SITE_URL : "https://playbrush.com"
                    // }
                  >
                    <GumLogo />
                  </Link>
                  <ThemeToggle />
                </LogoWrapper>
                {mutationCustomerCreate.isLoading ||
                mutationCheckoutOpen.isLoading ||
                mutationCheckoutUpdate.isLoading ||
                mutationCheckoutStatus.isLoading ||
                mutationPaypalSessionPay.isLoading ||
                paypalPaymentLoading ||
                !cartCheckDone ? (
                  <>
                    <InfoText>
                      {mutationPaypalSessionPay.isLoading &&
                        (translation?.payment_is_processing || "Payment is processing")}
                    </InfoText>
                    <LoaderWrapper>
                      <CircularProgress />
                    </LoaderWrapper>
                  </>
                ) : (
                  <>
                    <Grid container spacing={2} columnSpacing={6}>
                      <Grid
                        xs={12}
                        md={cartData && processing !== true ? 7 : 12}
                        item={true}
                        sx={{ mt: { xs: 0, md: 0 }, pt: { xs: 6, md: 6 } }}
                      >
                        <Box sx={{ m: { xs: 0, md: 0 }, p: { xs: 0, md: 0 } }}>
                          {mutationCustomerCreate.isLoading ||
                          mutationCheckoutOpen.isLoading ||
                          mutationCheckoutUpdate.isLoading ||
                          mutationCheckoutStatus.isLoading ||
                          !cartCheckDone ? (
                            <LoaderWrapper>
                              <CircularProgress />
                            </LoaderWrapper>
                          ) : (
                            <>
                              {activeStep === 0 && cartData !== null && processing !== true && (
                                <>
                                  <GrayWrapBox>
                                    <GrayWrapBoxTitle>
                                      <GrayWrapBoxTitleBackgroundSplit>
                                        {translation?.express_checkout || "Express Checkout"}
                                      </GrayWrapBoxTitleBackgroundSplit>
                                    </GrayWrapBoxTitle>
                                    <DescriptionText>
                                      {translation?.express_checkout_description || "Express Checkout description"}
                                    </DescriptionText>
                                    <SpaceBox />
                                    <ExpressCheckoutWrapper>
                                      <StripeExpressCheckoutElement />
                                    </ExpressCheckoutWrapper>
                                    <SpaceBox />
                                  </GrayWrapBox>
                                  <SpaceBox />
                                  <TermsAndConditions>
                                    {GetTermsAndConditionsText(translation, translation?.express_checkout_tc)}
                                  </TermsAndConditions>
                                  <SpaceBox />
                                  <GrayLineBox>
                                    <GrayWrapBoxTitle>
                                      <GrayWrapBoxTitleBackground>
                                        {translation?.checkout_or_divider || "OR"}
                                      </GrayWrapBoxTitleBackground>
                                    </GrayWrapBoxTitle>
                                  </GrayLineBox>
                                  <SpaceBox />
                                </>
                              )}
                              <StepperWrapper activeStep={activeStep}>
                                {steps.map((label, labelidx) => (
                                  <Step key={label} sx={{}}>
                                    <StepLabel
                                      icon={<StepIcon activeStep={activeStep} label={labelidx + 1} />}
                                      onClick={() => {
                                        if (activeStep > labelidx) {
                                          setActiveStep(labelidx);
                                        }
                                      }}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Box
                                        sx={{
                                          fontFamily: "Gotham",
                                          fontWeight: "600",
                                          color:
                                            activeStep < labelidx
                                              ? theme.palette.primary.main
                                              : theme.palette.secondary.main,
                                        }}
                                      >
                                        {label}
                                      </Box>
                                    </StepLabel>
                                  </Step>
                                ))}
                              </StepperWrapper>
                              <SpaceBox />
                              <React.Fragment>
                                {activeStep === steps.length ? (
                                  <React.Fragment>
                                    <Typography variant="h5" gutterBottom>
                                      Thank you for your order.
                                    </Typography>
                                    <Typography variant="subtitle1">
                                      Your order number is #2001539. We have emailed your order confirmation, and will
                                      send you an update when your order has shipped.
                                    </Typography>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <GetStepContent
                                      step={activeStep}
                                      register={register}
                                      control={control}
                                      errors={errors}
                                      getValues={getValues}
                                      setValue={setValue}
                                      cartData={cartData}
                                      translation={translation}
                                      mutationPaypalSessionPay={mutationPaypalSessionPay}
                                      setActiveStep={setActiveStep}
                                      setPaypalPaymentLoading={setPaypalPaymentLoading}
                                      setMessageSnackBarOpen={setMessageSnackBarOpen}
                                      setMessageSnackBar={setMessageSnackBar}
                                      payNowButtonRef={payNowButtonRef}
                                      isLoadingPayNowConfirm={isLoadingPayNowConfirm}
                                      setIsLoadingPayNowConfirm={setIsLoadingPayNowConfirm}
                                      processing={processing}
                                    />
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            </>
                          )}
                        </Box>
                      </Grid>
                      <Grid xs={12} md={cartData && processing !== true ? 5 : 0} item={true}>
                        {cartData && activeStep !== 2 && (
                          // Order Summary data
                          <GrayBox>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <OrderTitleText>{translation?.order_summary || "Order Summary"}</OrderTitleText>
                              <OrderNeedHelpText>{translation?.need_help || "Need help?"}</OrderNeedHelpText>
                            </Box>
                            <GrayLine />
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <OrderLineText>{translation?.subtotal || "Subtotal"}</OrderLineText>
                              <OrderLinePriceText>
                                {cartData && cartData.amountSubtotal && cartData.currency ? (
                                  <Currency price={cartData?.amountSubtotal} currency={cartData?.currency} />
                                ) : cartData && cartData.currency ? (
                                  <Currency price={0} currency={cartData?.currency} />
                                ) : (
                                  "0"
                                )}
                              </OrderLinePriceText>
                            </Box>

                            {cartData && cartData.currency ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <OrderLineShippingText
                                  sx={{
                                    color: cartData.amountShipping === 0 ? theme.palette.secondary.light : "inherit",
                                  }}
                                >
                                  {translation?.shipping_fee || "Shipping fee"}
                                </OrderLineShippingText>
                                <OrderLineShippingPriceText
                                  sx={{
                                    color: cartData.amountShipping === 0 ? "#00A944" : "inherit",
                                  }}
                                >
                                  {cartData.amountShipping === 0
                                    ? translation?.free || "FREE"
                                    : cartData?.amountShipping && (
                                        <Currency price={cartData?.amountShipping} currency={cartData?.currency} />
                                      )}
                                </OrderLineShippingPriceText>
                              </Box>
                            ) : (
                              ""
                            )}
                            {activeStep !== 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <OrderLineVATText>{translation?.vat || "VAT"}</OrderLineVATText>
                                <OrderLineVATPriceText>
                                  {cartData && cartData.amountTax && cartData.currency ? (
                                    <Currency price={cartData?.amountTax} currency={cartData?.currency} />
                                  ) : cartData && cartData.currency ? (
                                    <Currency price={0} currency={cartData?.currency} />
                                  ) : (
                                    "0"
                                  )}
                                </OrderLineVATPriceText>
                              </Box>
                            )}
                            {cartData && cartData.couponCode ? (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <OrderLineDiscountText>{translation?.discount || "Discount"}</OrderLineDiscountText>
                                  <OrderLineDiscountPriceText>
                                    {cartData && cartData.amountDiscount && cartData.currency ? (
                                      <Currency price={cartData?.amountDiscount} currency={cartData?.currency} />
                                    ) : cartData && cartData.currency ? (
                                      <Currency price={0} currency={cartData?.currency} />
                                    ) : (
                                      "0"
                                    )}
                                  </OrderLineDiscountPriceText>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <OrderLineDiscountText>
                                    {translation?.coupon_code || "Coupon Code"}
                                  </OrderLineDiscountText>
                                  <OrderLineDiscountPriceText>
                                    {cartData && cartData.couponCode ? cartData.couponCode : ""}
                                  </OrderLineDiscountPriceText>
                                </Box>
                              </>
                            ) : (
                              ""
                            )}
                            {
                              <Box
                                sx={{
                                  [theme.breakpoints.down("sm")]: {
                                    display: "none",
                                  },
                                }}
                              >
                                <GrayLine />
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <OrderTotalText>
                                    {translation?.total || "Total"}
                                    <OrderTotalVATText>{translation?.inc_tax || "Inkl. VAT"}</OrderTotalVATText>
                                  </OrderTotalText>
                                  <OrderTotalPriceText>
                                    {cartData && cartData.amountTotal && cartData.currency ? (
                                      <Currency price={cartData?.amountTotal} currency={cartData?.currency} />
                                    ) : cartData && cartData.currency ? (
                                      <Currency price={0} currency={cartData?.currency} />
                                    ) : (
                                      "0"
                                    )}
                                  </OrderTotalPriceText>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  {cartData && activeStep === 0 && (
                                    <ContinueButton onClick={handleNext} variant="contained">
                                      {translation?.continue_to_payment || "Continue to payment"}
                                    </ContinueButton>
                                  )}
                                  {cartData && activeStep === 1 && (
                                    <ContinueButton
                                      disabled={isLoadingPayNowConfirm}
                                      onClick={handlePayNow}
                                      variant="contained"
                                    >
                                      {isLoadingPayNowConfirm ? (
                                        <CircularProgress
                                          sx={{
                                            color: theme.palette.secondary.contrastText,
                                          }}
                                        />
                                      ) : (
                                        translation?.pay_now || "Pay Now"
                                      )}
                                    </ContinueButton>
                                  )}
                                </Box>
                              </Box>
                            }
                          </GrayBox>
                        )}
                        {cartData && activeStep === 1 && (
                          // Shipping information
                          <Box
                            sx={{
                              mx: "20px",
                            }}
                          >
                            {(cartContainsAdults || cartContainsKids) &&
                              (translation?.shipping_information_adults != null ||
                                translation?.shipping_infromation_kids != null) && (
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      mt: "34px",
                                    }}
                                  >
                                    <Typography
                                      variant="h4"
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        color: theme.palette.primary.main,
                                      }}
                                    >
                                      {translation?.shipping_information || "Shipping information"}
                                    </Typography>
                                  </Box>
                                  {cartContainsAdults && (
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        color: theme.palette.primary.main,
                                        mt: "14px",
                                      }}
                                    >
                                      {translation?.shipping_information_adults || ""}
                                    </Typography>
                                  )}
                                  {cartContainsKids && (
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        color: theme.palette.primary.main,
                                        mt: "14px",
                                      }}
                                    >
                                      {translation?.shipping_infromation_kids || ""}
                                    </Typography>
                                  )}
                                </Box>
                              )}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mt: "34px",
                              }}
                            >
                              <ShippingAddressDetails>
                                {translation?.shipping_address || "Shipping Address"}
                              </ShippingAddressDetails>
                              <ShippingAddressChange onClick={handleBack}>
                                {translation?.edit_shipping || "Edit"}
                              </ShippingAddressChange>
                            </Box>
                            <ShippingLineText sx={{ mt: "8px" }}>
                              {state.data.customer.shipping.name && state.data.customer.shipping.name}
                            </ShippingLineText>
                            <ShippingLineText>
                              {state.data.customer.shipping.address.line1 && state.data.customer.shipping.address.line1}{" "}
                              {state.data.customer.shipping.address.line2 && state.data.customer.shipping.address.line2}
                              {", "}
                              {state.data.customer.shipping.address.postalCode &&
                                state.data.customer.shipping.address.postalCode}
                            </ShippingLineText>
                            <ShippingLineText>
                              {state.data.customer.shipping.address.city && state.data.customer.shipping.address.city}
                              {", "}
                              {state.data.customer.shipping.address.state && state.data.customer.shipping.address.state}
                            </ShippingLineText>
                            <ShippingLineText>
                              {state.data.customer.shipping.address.country &&
                                state.data.customer.shipping.address.country}
                            </ShippingLineText>
                            <ShippingLineText>
                              {state.data.customer.email && state.data.customer.email}
                            </ShippingLineText>
                            {/* <ContinueButton onClick={handleBack}  variant="contained">Back</ContinueButton> */}
                          </Box>
                        )}
                        {/* <GrayBox>{JSON.stringify(errors)}</GrayBox> */}
                        {cartData && (activeStep === 0 || activeStep === 1) && (
                          // Fixed Order panel
                          <OrderOverlay>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <OrderTotalText>
                                {translation?.total || "Total"}
                                <OrderTotalVATText>{translation?.inc_tax || "Inkl. VAT"}</OrderTotalVATText>
                              </OrderTotalText>
                              <OrderTotalPriceText>
                                {cartData && cartData.amountTotal && cartData.currency ? (
                                  <Currency price={cartData?.amountTotal} currency={cartData?.currency} />
                                ) : cartData && cartData.currency ? (
                                  <Currency price={0} currency={cartData?.currency} />
                                ) : (
                                  "0"
                                )}
                              </OrderTotalPriceText>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                              {cartData && activeStep === 0 && (
                                <>
                                  <ContinueButton onClick={handleNext} variant="contained">
                                    {translation?.continue_to_payment || "Continue to payment"}
                                  </ContinueButton>
                                </>
                              )}
                              {cartData && (activeStep as number) === 1 && (
                                <ContinueButton
                                  disabled={isLoadingPayNowConfirm}
                                  onClick={handlePayNow}
                                  variant="contained"
                                >
                                  {isLoadingPayNowConfirm ? (
                                    <CircularProgress
                                      sx={{
                                        color: theme.palette.secondary.contrastText,
                                      }}
                                    />
                                  ) : (
                                    translation?.pay_now || "Pay Now"
                                  )}
                                </ContinueButton>
                              )}
                            </Box>
                          </OrderOverlay>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Container>
              <MessageSnackbars open={messageSnackBarOpen} setOpen={setMessageSnackBarOpen} message={messageSnackBar} />
            </CheckoutSetContext.Provider>
          </CheckoutContext.Provider>
        </ScopedCssBaseline>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
export default Checkout;
