import { useMutation, UseMutationOptions, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import { request, gql, ClientError, GraphQLClient } from "graphql-request";
import { apiBaseUrl, graphQLClient, __DEV__ } from "../api";
import moment from "moment";

// export function useCustomer(
//   onSuccessCustom?: (data: [GQL.Product]) => void
// ): UseQueryResult<[GQL.Product], ClientError> {
//   return useQuery(
//     "products",
//     async () => {
//       const { products: data } = await graphQLClient.request(
//         gql`
//           query {
//             products {
//               description
//               id
//               name
//               image
//               prices {
//                 id
//                 type
//                 currency
//                 unitAmount
//                 unitAmountDecimal
//               }
//             }
//           }
//         `
//       );
//       if (__DEV__) {
//         console.log(data);
//       }
//       return data;
//     },
//     {
//       onSuccess: (data: any) => {
//         if (onSuccessCustom !== undefined) onSuccessCustom!(data);
//       },
//     }
//   );
// }

export function useCustomerCreate(): UseMutationResult<
  GQL.CustomerCreate,
  ClientError,
  GQL.MutationCustomerCreateArgs
> {
  return useMutation(
    async (dataMutation) => {
      const data = await graphQLClient.request(
        gql`
            mutation{
              customerCreate(
                email: "${dataMutation.email}",
                description: "${dataMutation.description}",
                name: "${dataMutation.name}",
                phone: "${dataMutation.phone}",
                shipping:{
                  name: "${dataMutation.name}",
                  phone: "${dataMutation.phone}",
                  address:{
                    city:   "${dataMutation.shipping?.address?.city}",
                    country:  "${dataMutation.shipping?.address?.country}",
                    line1:  "${dataMutation.shipping?.address?.line1}",
                    line2:  "${dataMutation.shipping?.address?.line2}",
                    postalCode:  "${dataMutation.shipping?.address?.postalCode}",
                    state:  "${dataMutation.shipping?.address?.state}",
                  }
                },
                address:{
                  city: "${dataMutation.address?.city}",
                  country: "${dataMutation.address?.country}",
                  line1: "${dataMutation.address?.line1}",
                  line2: "${dataMutation.address?.line2}",
                  postalCode: "${dataMutation.address?.postalCode}",
                  state:  "${dataMutation.address?.state}",
                },
                currency: "${dataMutation.currency}",
                firstName: "${dataMutation.firstName}",
                lastName: "${dataMutation.lastName}",
                companyName: "${dataMutation.companyName}",
                language:"${dataMutation.language}",
              ) {
                success
                customerId
                customer{
                  id
                  name
                  email
                  description
                  address{
                    city
                    country
                    line1
                    line2
                    postalCode
                    state
                  }
                  shipping {
                    name
                    phone
                    address {
                      city
                      country
                      line1
                      line2
                      postalCode
                      state
                    }
                  }
                  phone
                  metadata
                }
              }

            }
        `
      );
      if (__DEV__) {
        console.log(data);
      }
      return data.customerCreate;
    },
    {
      onSuccess: (data, variables, context) => {},
      onError: (error: ClientError, variables, context) => {},
    }
  );
}

export function useCustomerUpdate(): UseMutationResult<
  // customerResult: UseQueryResult<[GQL.CustomerUpdate], ClientError>
  GQL.CustomerUpdate,
  ClientError,
  GQL.MutationCustomerUpdateArgs
> {
  return useMutation(
    async (dataMutation) => {
      const data = await graphQLClient.request(
        gql`
            mutation{
              customerUpdate(
                customerId: "${dataMutation.customerId}",
                email: "${dataMutation.email}",
                description: """${dataMutation.description}""",
                name: "${dataMutation.name}",
                phone: "${dataMutation.phone}",
                shipping:{
                  name: "${dataMutation.name}",
                  phone: "${dataMutation.phone}",
                  address:{
                    city:   "${dataMutation.shipping?.address?.state}",
                    country:  "${dataMutation.shipping?.address?.country}",
                    line1:  "${dataMutation.shipping?.address?.line1}",
                    line2:  "${dataMutation.shipping?.address?.line2}",
                    postalCode:  "${dataMutation.shipping?.address?.postalCode}",
                    state:  "${dataMutation.shipping?.address?.state}",
                  }
                },
                address:{
                  city: "${dataMutation.address?.city}",
                  country: "${dataMutation.address?.country}",
                  line1: "${dataMutation.address?.line1}",
                  line2: "${dataMutation.address?.line2}",
                  postalCode: "${dataMutation.address?.postalCode}",
                  state:  "${dataMutation.address?.state}",
                },
              ) {
                success
                customerId
                customer{
                  id
                  name
                  email
                  description
                  address{
                    city
                    country
                    line1
                    line2
                    postalCode
                    state
                  }
                  shipping {
                    name
                    phone
                    address {
                      city
                      country
                      line1
                      line2
                      postalCode
                      state
                    }
                  }
                  phone
                  metadata
                }
              }

            }
        `
      );
      if (__DEV__) {
        console.log(data);
      }
      return data.customerUpdate;
    },
    {
      onSuccess: (data, variables, context) => {
        // customerResult.refetch();
      },
      onError: (error: ClientError, variables, context) => {},
    }
  );
}
